import ls from 'local-storage';

import ready from './ready';

const currencyLocaleMap = {
  'USD': 'en-US',
  'SEK': 'sv-SE',
  'JPY': 'jp-JP',
  'EUR': 'de-DE',
  'KRW': 'ko-KO'
}

const lessThanOneHourAgo = (date) => {
    const HOUR = 1000 * 60 * 60;
    const anHourAgo = Date.now() - HOUR;
    return date && date > anHourAgo;
}

function updatePrices(selectedCurrency, rate) {
  const prices = document.getElementsByClassName('js-price');
  Array.from(prices).forEach(price => {
    const currentPrice = parseFloat(price.dataset.price);
    price.innerHTML = (currentPrice * rate).toLocaleString(currencyLocaleMap[selectedCurrency], { style: 'currency', currency: selectedCurrency, minimumFractionDigits: 0});
  });
}

function getPreferedCurrency(selectedCurrency) {
  const savedExchangeRate = ls.get(`SEK_${selectedCurrency}`);
  if (selectedCurrency === 'SEK') {
    updatePrices('SEK', 1);
  } else if (savedExchangeRate && lessThanOneHourAgo(savedExchangeRate.date)) {
    updatePrices(selectedCurrency, savedExchangeRate.rate);
  } else {
    fetchExchangeRate(selectedCurrency);
  }
  setIsActiveClass(selectedCurrency);
}

function fetchExchangeRate(selectedCurrency) {
  fetch(`https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/sek.json`)
    .then(checkStatus)
    .then(parseJSON)
    .then(function(data) {
      const rate = data['sek'][selectedCurrency.toLowerCase()];
      ls.set(`SEK_${selectedCurrency}`, { rate, date: Date.now() });
      updatePrices(selectedCurrency, rate);
    }).catch(function(error) {
      console.log('request failed', error)
    })
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    var error = new Error(response.statusText)
    error.response = response
    throw error
  }
}

function parseJSON(response) {
  return response.json()
}

function handleClickEvent(currencyLink) {
  const selectedCurrency = currencyLink.dataset.currency;
  ls.set('selectedCurrency', selectedCurrency);
  getPreferedCurrency(selectedCurrency);
}

function setupCurrencySelect(currencyLink) {
  currencyLink.addEventListener('click', (event) => handleClickEvent(currencyLink));
}

function initCurrencySelect() {
  const currencyLinks = document.getElementsByClassName('js-currency');
  Array.from(currencyLinks).forEach(setupCurrencySelect);
}

function setIsActiveClass(currency) {
  const currencyLinks = document.getElementsByClassName('js-currency');

  Array.from(currencyLinks).forEach(currencyLink => {
    if (currencyLink.dataset.currency === currency) {
      currencyLink.classList.add('is-active');
    } else {
      currencyLink.classList.remove('is-active');
    }
  });
}

function init() {
  const selectedCurrency = ls.get('selectedCurrency') || 'SEK';
  initCurrencySelect();
  getPreferedCurrency(selectedCurrency);
}

ready(init);
