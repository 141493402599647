import getYouTubeID from 'get-youtube-id';

import ready from './ready';
import template from '../templates/video.handlebars';

function setupVideoLink(videoLink) {
  videoLink.addEventListener('click', (event) => handleOpenModal(event, videoLink));
}

function handleCloseModal(event, closeLink) {
  closeLink.parentNode.removeChild(closeLink);
}

function handleOpenModal(event, videoLink) {
  const providerUid = getYouTubeID(videoLink.href);

  if (providerUid) {
    document.body.insertAdjacentHTML('beforeend', template({ providerUid }));
    const closeLinks = document.getElementsByClassName('js-video-modal-close');
    Array.from(closeLinks).forEach(closeLink => closeLink.addEventListener('click', (event) => handleCloseModal(event, closeLink)));
    event.preventDefault();
  }
}

function init() {
  const videoLinks = document.querySelectorAll('[href*=youtube]');

  Array.from(videoLinks).forEach(setupVideoLink);
}

ready(init);
