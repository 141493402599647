import ready from './ready';

function handleChangeEvent(langSelect) {
  window.location = langSelect.value;
}

function setupLangSelect(langSelect) {
  langSelect.addEventListener('change', (event) => handleChangeEvent(langSelect));
}

function init() {
  const langSelects = document.getElementsByClassName('js-lang-select');
  Array.from(langSelects).forEach(setupLangSelect);
}

ready(init);
