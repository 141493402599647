var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"video-modal-wrapper js-video-modal-close js-video-modal\">\n  <div class=\"video-close\"></div>\n  <div class=\"video-modal\">\n    <iframe id=\"player\" type=\"text/html\" width=\"640\" height=\"390\" src=\"https://www.youtube.com/embed/"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"providerUid") || (depth0 != null ? lookupProperty(depth0,"providerUid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"providerUid","hash":{},"data":data,"loc":{"start":{"line":4,"column":101},"end":{"line":4,"column":118}}}) : helper)))
    + "?enablejsapi=1&origin=http://example.com\" frameborder=\"0\"></iframe>\n  </div>\n</div>\n";
},"useData":true});